import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {storeToRefs} from "pinia";
import {getAuth} from "firebase/auth";
import {unref} from "vue";

import {
  DEFAULT_PARAMETERS_BY_SECTOR_ROUTE,
  DEFAULT_PARAMETERS_GENERAL_ROUTE,
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING,
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING,
  PARAMETERS_ROOT_ROUTE_NAME,
  SCHEDULING_ROUTES_NAMES_MAPPING,
  PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING,
  DEFAULT_PARAMETERS_IMPORTS_ROUTE,
} from "@/config/routes";
import {LOCAL_STORAGE} from "@/config/constants";
import {
  handleNewUserDataNavigation,
  handleRouteUserPermissions,
  handleRouteClientPermissions,
  routeRequires,
} from "@/tscript/utils/routerHelper";
import {useMainStore} from "@/stores/mainStore";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {useConnexionUser} from "@/composables/useConnexionUser";
import loggerHelper from "@/tscript/loggerHelper";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {GLOBAL_TABS_QUERY_PARAMETER} from "@/config/constants";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/home", // TODO verify query
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomePage.vue"),
    meta: {
      requiresAuth: true,
      icon: "home",
      hideTopNavbar: true,
    },
    beforeEnter: (_to: any, from: any, next: any) => {
      const {hasOnlySideModules, defaultRouteName} = storeToRefs(
        useMainStore(),
      );
      if (!unref(hasOnlySideModules)) return next();
      next({name: unref(defaultRouteName)});
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Analytics/DashboardAnalytics.vue"),
    meta: {
      requiresAuth: true,
      icon: "grid",
      title: "Analytics",
      permissions: ["general.read_analytics"],
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Admin.vue"),
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      icon: "monitor",
      title: "Admin",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {hideNavigation: true},
  },
  {
    path: "/profile/:id/:redirect",
    name: "profile",
    component: () => import("@/views/ProfileWrapper.vue"),
    meta: {
      requiresAuth: true,
      icon: "user",
      title: "Profile",
    },
    beforeEnter(to, _from, next): void {
      const permissionsStore = usePermissionsStore();

      const mainStore = useMainStore();
      const {userData, defaultRouteName} = storeToRefs(mainStore);

      if (to.params.id === userData.value?.id) return next();
      if (permissionsStore.currentPermissions.general.create_update_user)
        return next();
      next({name: unref(defaultRouteName)});
    },
  },
  {
    path: "/client/:id",
    name: "client",
    component: () => import("@/views/ClientWrapper.vue"),
    meta: {
      requiresAuth: true,
      icon: "user",
      title: "Client",
    },
  },
  {
    path: "/client-profile/:id/:redirect",
    name: "clientprofile",
    component: () => import("@/views/ProfileWrapper.vue"),
    meta: {
      requiresAuth: true,
      icon: "user",
      title: "Profile",
    },
  },
  {
    path: "/simulation",
    name: "simulation",
    component: () => import("@/views/Simulation/SimulationWrapper.vue"),
    meta: {
      requiresAuth: true,
      requiresPDP: true,
      icon: "sliders",
      title: "Planification",
    },
  },
  {
    path: "/plan-action",
    name: "planaction",
    component: () => import("@/views/PlanAction/PlanAction.vue"),
    meta: {
      requiresAuth: true,
      icon: "check-circle",
      title: "Plan",
      permissions: ["general.create_read_update_action"],
    },
  },
  {
    path: "/scheduling",
    component: () => import("@/views/Scheduling/SchedulingWrapper.vue"),
    meta: {
      requiresAuth: true,
      requiresScheduling: true,
      icon: "crosshair",
      title: "Planning",
    },
    children: [
      {
        path: "planning",
        name: SCHEDULING_ROUTES_NAMES_MAPPING.planning,
        component: () => import("@/views/Scheduling/Planning/Planning.vue"),
        meta: {
          icon: "none",
          title: "Scheduling",
        },
      },
      {
        path: "piloting",
        name: SCHEDULING_ROUTES_NAMES_MAPPING.piloting,
        component: () => import("@/views/Scheduling/Piloting/Piloting.vue"),
        meta: {
          icon: "none",
          title: "Ordo",
        },
      },
    ],
  },
  {
    path: "/customer-interface",
    name: "customer-interface",
    component: () => import("@/views/CustomerInterface.vue"),
    meta: {
      requiresAuth: true,
      requiresCustomerInterface: true,
      icon: "users",
      title: "Customer-interface",
    },
  },
  {
    path: "/import-file",
    name: "importfile",
    component: () => import("@/views/ImportFile.vue"),
    meta: {
      requiresAuth: true,
      icon: "database",
      title: "Import",
    },
  },
  {
    path: "/parametres",
    name: PARAMETERS_ROOT_ROUTE_NAME,
    component: () => import("@/views/Parametres/Layout/Parametres.vue"),
    meta: {
      requiresAuth: true,
      icon: "settings",
      title: "Paramètres",
      permissions: ["general.read_parameters"],
    },
    redirect: {name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.root},
    children: [
      {
        path: "general",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.root,
        redirect: {name: DEFAULT_PARAMETERS_GENERAL_ROUTE},
      },
      {
        path: "general/structure-usine",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.factoryStructure,
        component: () =>
          import("@/views/Parametres/General/ParametersFactoryStructure.vue"),
      },
      {
        path: "general/groupes-secteurs",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.sectorsGroups,
        component: () =>
          import("@/views/Parametres/General/ParametersSectorsGroups.vue"),
      },
      {
        path: "general/liste-operateurs",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.operatorsList,
        component: () =>
          import("@/views/Parametres/General/ParametersOperatorsList.vue"),
      },
      {
        path: "general/liste-machines",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.machinesList,
        component: () =>
          import("@/views/Parametres/General/ParametersMachinesList.vue"),
      },
      {
        path: "general/utilisateurs-oplit",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.oplitUsers,
        component: () =>
          import("@/views/Parametres/General/ParametersOplitUsers.vue"),
      },
      {
        path: "general/liste-parametres",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.parametersList,
        component: () =>
          import("@/views/Parametres/General/ParametersParametersList.vue"),
      },
      {
        path: "general/regles-calcul",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.calculusRules,
        component: () =>
          import(
            "@/views/Parametres/General/ParametersCalculusRulesGeneral.vue"
          ),
      },
      {
        path: "general/capacite-journaliere",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.dailyCapacity,
        component: () =>
          import("@/views/Parametres/General/ParametersDailyCapacity.vue"),
      },
      {
        path: "general/regles-ordonnancement",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.schedulingRules,
        component: () =>
          import(
            "@/views/Parametres/General/ParametersSchedulingRulesGeneral.vue"
          ),
      },
      {
        path: "general/affichage-planification",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.planningDisplay,
        component: () =>
          import("@/views/Parametres/General/ParametersPlanningDisplay.vue"),
      },
      {
        path: "general/affichage-ordonnancement",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.schedulingDisplay,
        component: () =>
          import("@/views/Parametres/General/ParametersSchedulingDisplay.vue"),
      },
      {
        path: "general/stocks",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.stocks,
        component: () =>
          import("@/views/Parametres/General/ParametersStocks.vue"),
      },
      {
        path: "general/nomenclature",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.nomenclatures,
        component: () =>
          import("@/views/Parametres/General/ParametersNomenclatures.vue"),
      },
      {
        path: "general/customer-provider-display",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.customerProviderDisplay,
        component: () =>
          import(
            "@/views/Parametres/General/ParametersCustomerProviderDisplay.vue"
          ),
      },
      {
        path: "general/oplit-date-calculation",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.oplitDateCalculation,
        component: () =>
          import(
            "@/views/Parametres/General/ParametersOplitDateCalculation.vue"
          ),
      },
      {
        path: "general/configuration-modules",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.modules,
        component: () =>
          import(
            "@/views/Parametres/General/ParametersConfigurationModules.vue"
          ),
      },
      {
        path: "secteur",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.root,
        redirect: {name: DEFAULT_PARAMETERS_BY_SECTOR_ROUTE},
      },
      {
        path: "secteur/regles-calcul",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.calculusRules,
        component: () =>
          import(
            "@/views/Parametres/BySector/ParametersCalculusRulesBySector.vue"
          ),
      },
      {
        path: "secteur/calendrier",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.calendar,
        component: () =>
          import("@/views/Parametres/BySector/ParametersCalendar.vue"),
      },
      {
        path: "secteur/regles-ordonnancement",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.schedulingRules,
        component: () =>
          import(
            "@/views/Parametres/BySector/ParametersSchedulingRulesBySector.vue"
          ),
      },
      {
        path: "secteur/informations",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.sectorInformation,
        component: () =>
          import("@/views/Parametres/BySector/ParametersSectorInformation.vue"),
      },
      {
        path: "secteur/planning-standard",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.standardPlanning,
        component: () =>
          import("@/views/Parametres/BySector/ParametersStandardPlanning.vue"),
      },
      {
        path: "secteur/stock-cible",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.targetStock,
        component: () =>
          import("@/views/Parametres/BySector/ParametersTargetStock.vue"),
      },
      {
        path: "secteur/encours-cible",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.targetWIP,
        component: () =>
          import("@/views/Parametres/BySector/ParametersTargetWIP.vue"),
      },
      {
        path: "secteur/load-formula",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.loadFormula,
        component: () =>
          import("@/views/Parametres/BySector/ChargeMachines.vue"),
      },
      {
        path: "secteur/load-calculus-rules",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.loadCalculusRules,
        component: () =>
          import(
            "@/views/Parametres/BySector/ParametersLoadCalculusRulesBySector.vue"
          ),
      },
      {
        path: "secteur/dispatch-rules",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.dispatchRules,
        component: () =>
          import("@/views/Parametres/BySector/ParametersDispatchRules.vue"),
      },
      {
        path: "import",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.root,
        redirect: {name: DEFAULT_PARAMETERS_IMPORTS_ROUTE},
      },
      {
        path: "import/import-data",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importData,
        component: () => import("@/views/Parametres/Imports/ImportHistory.vue"),
      },
      {
        path: "import/import-rules",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importRules,
        component: () => import("@/views/Parametres/Imports/ImportRules.vue"),
      },
      {
        path: "import/import-parsing-rules",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importParsingRules,
        component: () =>
          import("@/views/Parametres/Imports/ImportParsingRules.vue"),
      },
    ],
  },
  {
    path: "/frames",
    component: () => import("@/views/Frames/Frames.vue"),
    meta: {
      requiresAuth: true,
      icon: "settings",
      title: "Frames",
    },
    children: [
      {
        path: "",
        name: "frames",
        component: () => import("@/views/Frames/Frames.vue"),
      },
      {
        path: "adhesion-rate",
        name: "adhesionrate",
        component: () => import("@/views/Frames/AdhesionRateFrame.vue"),
      },
      {
        path: "favorite-card",
        name: "favoritecard",
        component: () => import("@/views/Frames/FavoriteCardFrame.vue"),
      },
      {
        path: "monitor-production",
        name: "monitorproduction",
        component: () => import("@/views/Frames/MonitorProductionFrame.vue"),
      },
    ],
  },
  {
    path: "/suivi",
    name: "suivi",
    component: () => import("@/views/Suivi.vue"),
    meta: {
      requiresAuth: true,
      title: "Suivi",
    },
  },
  {
    path: "/dev",
    name: "dev",
    component: () => import("@/views/Dev/Dev.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "monitor",
      title: "Dev",
    },
  },
  {
    path: "/tech-kpis",
    name: "tech-kpis",
    component: () => import("@/views/Dev/TechKPIs.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "monitor",
      title: "KPI",
    },
  },
  {
    path: "/tech-performance",
    name: "tech-performance",
    component: () => import("@/views/Dev/TechPerformance.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "monitor",
      title: "Tech-performance",
    },
  },
  {
    path: "/list-absolute-ofs",
    name: "list-absolute-ofs",
    component: () => import("@/views/ListAbsoluteOFs.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "database",
      title: "List-absolute-ofs",
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  loggerHelper.log({to, _from});
  const query = {...(to.query || {})};
  const mainStore = useMainStore();
  const {setSelectedTab} = useGlobalTabs();
  const {
    userData,
    clientParameters,
    redirect,
    isScheduling,
    currentQuery,
    defaultRouteName,
    apiClient,
    isAppLoading,
  } = storeToRefs(mainStore);

  const {reloadUser} = useConnexionUser();
  currentQuery.value = query;

  if (!isAppLoading.value && to.name !== _from.name)
    apiClient.value.cancelOngoingRequests();

  // check to see if route has auth guard
  if (
    [
      "requiresAuth",
      "requiresAdmin",
      "requiresSuperAdmin",
      "requiresScheduling",
      "requiresPDP",
      "requiresCustomerInterface",
    ].some((metaKey: string): boolean => routeRequires(to, metaKey))
  ) {
    // check auth state of user
    const user = getAuth().currentUser;

    if (!user) {
      redirect.value = {path: to.path, query};
      return next({name: "login", query});
    } else if (!userData.value?.id) await reloadUser(user);

    const permissionsStore = usePermissionsStore();
    await permissionsStore.permissionsLoadingPromise;
    const hasUserPermissions = handleRouteUserPermissions(
      to,
      permissionsStore.currentPermissions,
    );
    const hasClientPermissions = handleRouteClientPermissions(
      to,
      unref(clientParameters),
    );

    let queryOrUndefined: Record<string, any> | undefined;
    // //This is a special condition to allow ADMIN users to easily change client when navigating from a link
    // if (
    //   query.cid &&
    //   query.cid !== userData.value?.client_id &&
    //   userData.value?.role === USER_ROLES.ADMIN
    // )
    //   await changeClient(query.cid as string);
    // else
    if (
      userData.value?.client_id &&
      (!query.cid || query.cid !== userData.value?.client_id)
    ) {
      //this makes sure to save the client_id in the URL query
      query.cid = userData.value.client_id;
      queryOrUndefined = {...to, query}; //to avoid redundant navigation, we define this object only once, and leave it undefined the other times
    }

    const gtab = to.query[GLOBAL_TABS_QUERY_PARAMETER];
    if (gtab) {
      const selectedTab = typeof gtab === "string" ? gtab : gtab[0];
      setSelectedTab(+selectedTab, {
        shouldNotUpdateRoute: true,
        shouldNotCancelRequests: true,
      });
    }

    if (!hasUserPermissions || !hasClientPermissions)
      return next({name: unref(defaultRouteName)});

    if (redirect.value?.path) redirect.value = {};
    if (
      ["requiresAdmin", "requiresSuperAdmin", "requiresScheduling"].every(
        (metaKey: string): boolean => !routeRequires(to, metaKey),
      )
    ) {
      if (
        userData.value?.has_scheduling &&
        userData.value?.has_production_planning
      )
        isScheduling.value = false;

      // User is authorized. Proceed to route
      return next(queryOrUndefined);
    }

    const localUserData = localStorage.getItem(LOCAL_STORAGE.USER_DATA) || "{}";
    /**
     * we fallback the user data to a locally stored object to handle the
     * multiple requirements routes & the async loading of userData
     * the values are synchronized
     */
    const finalUserData = userData.value || JSON.parse(localUserData);

    if (finalUserData?.id !== user.uid)
      return next({name: unref(defaultRouteName), query});

    const redirectRouteProps = handleNewUserDataNavigation(finalUserData, to);

    if (redirectRouteProps) return next({...redirectRouteProps, query});

    // setting the "is_scheduling" state from the route requirements
    if (finalUserData?.has_scheduling && finalUserData?.has_production_planning)
      isScheduling.value = routeRequires(to, "requiresScheduling");

    next(queryOrUndefined);
  } else if (to.name === "login") {
    // check auth state of user
    const user = getAuth().currentUser;
    if (user) {
      if (redirect.value?.path) redirect.value = {};
      // user is signed in. Redirect to login
      next({name: unref(defaultRouteName), query});
    } else {
      // No user is signed in. Redirect to login
      next();
    }
  } else {
    if (to.matched.length < 1)
      return next({name: unref(defaultRouteName), query});

    if (redirect.value?.path) redirect.value = {};
    // if route is not guarded by auth, proceed
    next();
  }
});

export {routes};
export default router;
