<script setup lang="ts">
import {computed, defineAsyncComponent, defineOptions, h, useAttrs} from "vue";

interface OplitIconProps {
  name: string;
  size?: string | number;
  backgroundColor?: string;
  clickable?: boolean;
}

const props = withDefaults(defineProps<OplitIconProps>(), {
  size: "16px",
});

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();

const svgFile = defineAsyncComponent(
  () => import(`../../assets/icons/${props.name}.svg`),
);

const strSize = computed(() =>
  typeof props.size === "string" ? props.size : `${props.size}px`,
);
const renderedComponent = computed(() => {
  const classes = [attrs.class || ""];
  if (props.clickable) classes.push("cursor-pointer");
  const style = Object.assign(
    {},
    {flex: `0 0 ${strSize.value}`},
    attrs.style || {},
  );

  const svg = h(svgFile, {
    height: strSize.value,
    width: strSize.value,
    ...attrs,
    class: classes.filter(Boolean),
    style,
  });

  if (!props.backgroundColor) return svg;

  return h("div", {class: `with-background bg-${props.backgroundColor}`}, svg);
});
</script>

<template>
  <component :is="renderedComponent" />
</template>

<style scoped lang="scss">
.with-background {
  display: flex;
  padding: 8px;
  border-radius: 8px;
}
</style>
