import {optionize} from "@/tscript/utils/generalHelpers";

const SCHEDULING_CALENDAR_VIEW_CLASSIC = "classic";
const SCHEDULING_CALENDAR_VIEW_GANTT = "gantt";
const SCHEDULING_OFS_VIEW_LIST = "list";
const SCHEDULING_OFS_VIEW_GANTT = SCHEDULING_CALENDAR_VIEW_GANTT;
/**
 * object whose keys and values are the ones in the array
 * better readibility invoking e.g. `.month` rather than `[0]` with a normal array
 */

const SCHEDULING_GANTT_MESHES_OPTIONS = optionize([
  "month",
  "two-weeks",
  "week",
]);
const SCHEDULING_GANTT_SHIFTS_OPTIONS = {
  morning: "M",
  afternoon: "AM",
  night: "N",
};

const CSS_SCHEDULING_GANTT_CELL_CLASS = "gantt--cell";
const SCHEDULING_GANTT_PER_PAGE_IDENTIFIER = "ganttPerPage";
const SCHEDULING_GANTT_PER_PAGE_DEFAULT_VALUE = 50;

const SCHEDULING_TAGS_DEFAULT_COLOR = "newPrimaryLight2";
const SCHEDULING_TAGS_AVAILABLE_COLORS = [
  "newGreenLight2",
  SCHEDULING_TAGS_DEFAULT_COLOR,
  "newPurpleLight2",
  "newPinkLight2",
  "newOrangeLight2",
  "newLightGrey",
];

const SCHEDULING_COLOR_CATEGORY_ID_SCHEDULING_CALENDAR_PLANNING =
  "planning__calendar__color_category";
const SCHEDULING_COLOR_CATEGORY_ID_OFS_PLANNING =
  "planning__ofs__color_category";
const SCHEDULING_COLOR_CATEGORY_ID_SCHEDULING_CALENDAR_PILOTING =
  "piloting__calendar__color_category";
const SCHEDULING_COLOR_CATEGORY_ID_OFS_PILOTING =
  "piloting__ofs__color_category";
const SCHEDULING_COLOR_CATEGORY_ID_EN_COURS_PILOTING =
  "piloting__en_cours__color_category";

const SCHEDULING_GROUP_BY_ID_SCHEDULING_CALENDAR_PLANNING =
  "planning__calendar__group_by";
const SCHEDULING_GROUP_BY_ID_SCHEDULING_CALENDAR_PILOTING =
  "piloting__calendar__group_by";
const SCHEDULING_GROUP_BY_ID_EN_COURS_PILOTING = "piloting__en_cours__group_by";
const SCHEDULING_CALENDAR_DEFAULT_COLUMN_WIDTH = 250;

const SCHEDULING_KEY_FIELDS_FOR_AI_RULES = ["tags", "fast_track", "incident"];
const SCHEDULING_EXCLUDED_FIELDS_FOR_AI_RULES = [
  "erp_id",
  "unite_of",
  "unite_op",
  "unite_op_2",
];

const CSS_SCHEDULING_OPERATION_PRIORITY_CHIP_CLASS = "chip";

const PRIORITY_RULES_STORAGE_IDENTIFIER = "filter_priorityRules";
const ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER = "filter_enableManualSorting";

const CAPACITY_TABLE_DEFAULT_DAILY_VALUE = null;

const LIST_OFS_ABSOLUTE_OFS_STORAGE_ID = "list_absolute_ofs";

const OPERATION_CARD_V_LAZY_MIN_HEIGHT = 90;

export {
  SCHEDULING_OFS_VIEW_LIST,
  SCHEDULING_CALENDAR_VIEW_CLASSIC,
  SCHEDULING_CALENDAR_VIEW_GANTT,
  SCHEDULING_GANTT_MESHES_OPTIONS,
  SCHEDULING_GANTT_SHIFTS_OPTIONS,
  SCHEDULING_OFS_VIEW_GANTT,
  CSS_SCHEDULING_GANTT_CELL_CLASS,
  SCHEDULING_GANTT_PER_PAGE_IDENTIFIER,
  SCHEDULING_GANTT_PER_PAGE_DEFAULT_VALUE,
  SCHEDULING_TAGS_DEFAULT_COLOR,
  SCHEDULING_TAGS_AVAILABLE_COLORS,
  SCHEDULING_COLOR_CATEGORY_ID_SCHEDULING_CALENDAR_PLANNING,
  SCHEDULING_COLOR_CATEGORY_ID_OFS_PLANNING,
  SCHEDULING_COLOR_CATEGORY_ID_SCHEDULING_CALENDAR_PILOTING,
  SCHEDULING_COLOR_CATEGORY_ID_OFS_PILOTING,
  SCHEDULING_COLOR_CATEGORY_ID_EN_COURS_PILOTING,
  SCHEDULING_GROUP_BY_ID_SCHEDULING_CALENDAR_PLANNING,
  SCHEDULING_GROUP_BY_ID_SCHEDULING_CALENDAR_PILOTING,
  SCHEDULING_GROUP_BY_ID_EN_COURS_PILOTING,
  SCHEDULING_CALENDAR_DEFAULT_COLUMN_WIDTH,
  SCHEDULING_KEY_FIELDS_FOR_AI_RULES,
  SCHEDULING_EXCLUDED_FIELDS_FOR_AI_RULES,
  CSS_SCHEDULING_OPERATION_PRIORITY_CHIP_CLASS,
  PRIORITY_RULES_STORAGE_IDENTIFIER,
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
  CAPACITY_TABLE_DEFAULT_DAILY_VALUE,
  LIST_OFS_ABSOLUTE_OFS_STORAGE_ID,
  OPERATION_CARD_V_LAZY_MIN_HEIGHT,
};
