<template>
  <v-tooltip location="top" content-class="capa-tooltip">
    <template v-slot:activator="{props}">
      <span v-bind="props">
        <slot>
          {{ activatorDefaultText }}
        </slot>
      </span>
    </template>

    <div
      class="capa-tooltip-wrapper"
      :class="{'use-divider': !!chargeTable.length}"
    >
      <div v-if="displayPeriodLabel" class="capa-tooltip-item">
        {{ computedDateValue }}
      </div>
      <div v-if="!isPast" class="capa-tooltip-item">
        <div class="fd-flex-center gap-2">
          <div class="custom-chip bg-newPrimaryDark2" />
          <div>{{ $t("Simulation.capa") }}</div>
        </div>
        <div class="capa-tooltip-value">{{ prettyNum(computedCapa) }}</div>
      </div>
      <div v-if="!isPast" class="capa-tooltip-item">
        <div class="capa-tooltip-item">
          <div class="fd-flex-center gap-2">
            <div
              v-if="!chargeTable.length"
              class="custom-chip bg-newPrimaryRegular"
            />
            <div>
              {{ $t(`global.${chargeTable.length ? "total_load" : "load"}`) }}
            </div>
          </div>
          <div class="capa-tooltip-value" data-testid="capa-tooltip-load">
            {{ prettyNum(computedCharge) }}
          </div>
        </div>
        <template v-if="chargeTable.length">
          <div
            v-for="({of_id, load}, idx) of chargeTable"
            :key="`${of_id}_${load}_${idx}`"
            class="capa-tooltip-item font-weight-regular"
          >
            <div class="fd-flex-center gap-2">
              <div class="custom-chip bg-newPrimaryRegular" />
              <div>
                {{
                  `${$t("global.load")} (${getReadableImportParsingRuleValue(
                    of_id,
                  )})`
                }}
              </div>
            </div>
            <div class="capa-tooltip-value" data-testid="capa-tooltip-load">
              {{ prettyNum(load) }}
            </div>
          </div>
        </template>
      </div>
      <div v-if="computedDelay" class="capa-tooltip-item">
        <div class="fd-flex-center gap-2">
          <div class="custom-chip bg-newPinkRegular" />
          <div>{{ $t("global.delay") }}</div>
        </div>
        <div class="capa-tooltip-value">{{ prettyNum(computedDelay) }}</div>
      </div>
      <div class="capa-tooltip-item">
        <div class="fd-flex-center gap-2">
          <div
            :class="[
              'custom-chip',
              isCapaNumeral && +capaPercentage <= 100
                ? 'bg-newGreenRegular'
                : 'bg-newPinkDark1',
            ]"
          />
          <div>{{ $t("Simulation.load_rate") }}</div>
        </div>
        <div class="capa-tooltip-value">
          {{ capaPercentage }}{{ isCapaNumeral ? "%" : "" }}
        </div>
      </div>
      <div v-if="displayDelta" class="capa-tooltip-item">
        <div class="fd-flex-center gap-2">
          <div class="custom-chip bg-newPurpleRegular" />
          <div v-if="remainingCapa < 0">
            {{ $t("Simulation.missing_capa") }}
          </div>
          <div v-else>{{ $t("Simulation.remaining_capa") }}</div>
        </div>
        <div class="capa-tooltip-value">
          {{ prettyNum(Math.abs(remainingCapa)) }}
        </div>
      </div>
    </div>
  </v-tooltip>
</template>
<script lang="ts">
import {computed, defineComponent} from "vue";
import {storeToRefs} from "pinia";
import numeral from "numeral";
import {useMainStore} from "@/stores/mainStore";
import {PropType} from "vue";
import {
  getDisplayedPeriod,
  getReadableImportParsingRuleValue,
} from "@oplit/shared-module";
import {useI18n} from "vue-i18n";

numeral.locale("fr");

export default defineComponent({
  name: "capa-tooltip",
  props: {
    capaPercentage: {type: [String, Number], default: ""},
    computedCharge: {type: Number, default: 0},
    computedCapa: {type: Number, default: 0},
    computedDelay: {type: Number, default: 0},
    remainingCapa: {type: Number, default: 0},
    isPast: {type: Boolean, default: false},
    textOnly: {type: Boolean, default: false},
    displayDelta: {type: Boolean, default: false},
    unit: {type: String, default: ""},
    isCapaNumeral: {type: Boolean, default: false},
    textPrefix: {type: String, default: null},
    textSuffix: {type: String, default: null},
    chargeTable: {
      type: Array as PropType<{of_id: string; load: number}[]>,
      default: () => [] as {of_id: string; load: number}[],
    },
    displayPeriodLabel: {type: Boolean, default: false},
    period: {type: Object, default: () => ({})},
  },
  setup(props) {
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);
    const {locale} = useI18n();

    const activatorDefaultText = computed(() =>
      [
        props.textPrefix,
        props.textOnly ? `( ${props.capaPercentage} )` : props.capaPercentage,
        props.textSuffix,
      ]
        .filter(Boolean)
        .join(" "),
    );

    const computedDateValue = computed(() =>
      getDisplayedPeriod(
        props.period.startDate,
        props.period.maille === "month",
        locale.value,
        true,
      ),
    );

    return {
      activatorDefaultText,
      variables,
      computedDateValue,
      getReadableImportParsingRuleValue,
    };
  },
  methods: {
    prettyNum(num: string | number) {
      const formattedNum = numeral(num).format("0,0.[00]");
      const unit = this.unit === "%" ? this.unit : ` ${this.unit}`;
      return `${formattedNum}${unit}`;
    },
  },
});
</script>

<style scoped lang="scss">
.capa-tooltip {
  z-index: 11 !important;
  border-radius: 0;

  & .capa-tooltip-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 8px;
    column-gap: 12px;

    & .capa-tooltip-item {
      display: grid;
      grid-column: 1/-1;
      grid-template-columns: subgrid;
      row-gap: 8px;
      column-gap: 12px;
      justify-content: space-between;
      color: black;
      font-size: 14px;
      font-weight: 600;

      & .capa-tooltip-value {
        text-align: right;
      }
    }

    &.use-divider > .capa-tooltip-item:not(:first-child) {
      border-top: 1px rgb(var(--v-theme-newSelected)) solid;
      padding-top: 8px;
    }

    & .custom-chip {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}
</style>
