<template>
  <v-dialog
    v-if="canOperateOnOperationCards"
    v-model="operationsEditDialog"
    width="500"
    persistent
  >
    <template v-slot:activator="{props}">
      <FButton
        v-bind="props"
        width="100%"
        outlined
        data-testid="operations-actions-menu-edit-button"
      >
        <vue-feather type="edit-3" size="21" />
        <span class="fbody-2">{{ $t("Operations.edit-btn-text") }}</span>
      </FButton>
    </template>

    <div
      v-if="operationsEditDialog"
      :class="[
        'operation-card-edit-dialog',
        CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      ]"
    >
      <h4 class="fd-flex-center gap-4 text-newMainText semi-bold">
        <vue-feather type="edit-3" size="21" />
        {{ $t("Operations.edit-dialog-title") }}
      </h4>

      <div>
        {{
          $t(
            "Operations.edit-dialog-subtitle",
            {
              selectedCardsCount: selectedOpsIds.length,
            },
            selectedOpsIds.length,
          )
        }}
      </div>

      <div class="fd-flex-center">
        <div class="flex-1 d-flex flex-column gap-4">
          <strong>
            {{ $t("Operations.edit-dialog-current-date") }}
          </strong>
          <span> {{ currentStartDate }} </span>
        </div>
        <div class="flex-1 d-flex flex-column gap-4">
          <strong>
            {{ $t("Operations.edit-dialog-current-sector") }}
          </strong>
          <span>
            {{ currentSector?.name ?? $t("scheduling.different_by_of") }}
          </span>
        </div>
      </div>

      <div
        v-if="currentPermissions.scheduling.update_of_date"
        class="d-flex flex-column gap-4"
      >
        <strong> {{ $t("Operations.edit-dialog-new-date") }} </strong>
        <FDatePicker
          field-class="fd-flex-center justify-center"
          :placeholder="$t('Simulation.enter_a_date')"
          :date-value="computeDate(computeFieldValue('new_date', dateFirstOP))"
          :field-value="computeFieldValue('new_date', dateFirstOP)"
          :handle-change="(v) => setFieldValue(v, 'new_date')"
          :can-select-shift="doesClientHaveShiftPlanning"
          :disabled="hasDoneOps"
          data-testid="operations-edit-dialog-date-picker"
          class="w-100"
        />
      </div>

      <div
        v-if="currentPermissions.scheduling.update_of_machine"
        class="d-flex flex-column gap-4"
      >
        <strong>
          {{ $t("Operations.edit-dialog-new-sector") }}
        </strong>
        <SectorsSelector
          class="w-100 CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
          only-dropdown
          can_make_change
          full-size
          :selected-machine-prop="computedMachineCenter"
          data-testid="operations-edit-dialog-sectors-selector"
          @update-sector="setMachineCenter"
        />
      </div>

      <div class="d-flex gap-4">
        <v-spacer></v-spacer>
        <FButton outlined @click="() => (operationsEditDialog = false)">
          {{ $t("global.cancel") }}
        </FButton>
        <FButton
          filled
          :loading="loading"
          :disabled="shouldDisableBtn"
          data-testid="operations-edit-dialog-validate-button"
          @click="onEditOperationConfirm"
        >
          {{ $t("global.valider") }}
        </FButton>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import {
  ComputedRef,
  computed,
  defineComponent,
  inject,
  ref,
  unref,
  PropType,
  watch,
} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import _ from "lodash";
import {FButton} from "@/components/Global";
import FDatePicker from "@/components/Global/FDatePicker.vue";
import {SectorsSelector} from "@/components/Scheduling/Navbar/NavbarComponents";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS} from "@/config/constants";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGanttStore} from "@/stores/ganttStore";

import {useMainStore} from "@/stores/mainStore";
import {useParameterStore} from "@/stores/parameterStore";
import {SchedulingOperation, Sector} from "@/interfaces";
import {
  includeOriginalUpdateShiftPlanningInSiblingDate,
  pgOpsMapFn,
} from "@oplit/shared-module";
import {hasArrayDoneOperations} from "@/tscript/utils/schedulingUtils";
import {useSelectedOperations} from "@/stores/selectedOperationsStore";

export default defineComponent({
  components: {
    FButton,
    FDatePicker,
    SectorsSelector,
  },
  props: {
    selectedOpsIds: {type: Array as PropType<string[]>, default: () => []},
  },
  setup(props) {
    const schedulingStore = useSchedulingStore();
    const {sendSchedulingLoadEventToBackend, setPgOpsModificationsFromUpdate} =
      schedulingStore;
    const {
      selectedSimulation,
      pgOpsModifications,
      schedulingMachineCenters,
      canOperateOnOperationCards,
      shouldHandleOPDuration,
    } = storeToRefs(schedulingStore);
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {ganttEntitiesToReload} = storeToRefs(useGanttStore());
    const {doesClientHaveShiftPlanning} = storeToRefs(useParameterStore());

    const mainStore = useMainStore();
    const {userData, stationsAndMachinesTags, clientParameters, apiClient} =
      storeToRefs(mainStore);
    const {getCtxNextAvailableDate, getCtxSectorOrderedCalendars} = mainStore;
    const {hasOverlayOpened} = storeToRefs(useSelectedOperations());

    const hasShiftPlanning = inject<ComputedRef<boolean>>("hasShiftPlanning");
    const calendarOperations = inject<SchedulingOperation[]>("pg_ops", []);
    const operationsByEntity = inject<Record<string, SchedulingOperation[]>>(
      "operationsByEntity",
      {},
    );

    const operationsEditDialog = ref<boolean>(false);

    const computedSelectedOps = computed<SchedulingOperation[]>(() => {
      const operationsByEntityValues = Object.values(unref(operationsByEntity));
      const ops = operationsByEntityValues.length
        ? operationsByEntityValues
        : unref(calendarOperations);
      const operationsArray = [...ops].flat().filter(Boolean);

      if (!operationsArray.length || !props.selectedOpsIds.length) return [];
      return operationsArray.filter(({op_id}) =>
        props.selectedOpsIds.includes(op_id),
      );
    });

    const hasDoneOps = computed(() =>
      hasArrayDoneOperations(computedSelectedOps.value),
    );

    watch(
      operationsEditDialog,
      (isShown) => (hasOverlayOpened.value = isShown),
    );

    return {
      operationsEditDialog,
      modifications: ref<Record<string, unknown>>({}),
      loading: ref<boolean>(false),
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      selectedSimulation,
      pgOpsModifications,
      schedulingMachineCenters,
      currentPermissions,
      ganttEntitiesToReload,
      userData,
      stationsAndMachinesTags,
      clientParameters,
      apiClient,
      hasShiftPlanning,
      canOperateOnOperationCards,
      calendarOperations,
      operationsByEntity,
      computedSelectedOps,
      getCtxNextAvailableDate,
      getCtxSectorOrderedCalendars,
      doesClientHaveShiftPlanning,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      setPgOpsModificationsFromUpdate,
      hasDoneOps,
    };
  },
  computed: {
    dateFirstOP() {
      if (!this.computedSelectedOps.length || !this.selectedOpsIds) return;
      const firstId: string = _.first(this.selectedOpsIds);
      const first: any = this.computedSelectedOps.find(
        (o: any) => firstId === o.op_id,
      );
      if (!first) return;
      return first?.new_date || first?.day_date;
    },
    currentStartDate(): string {
      const {computedSelectedOps, dateFirstOP} = this;
      if (
        computedSelectedOps.some(
          (x: any) => (x.new_date ?? x.day_date) !== dateFirstOP,
        )
      )
        return this.$t("scheduling.different_by_of");
      else return moment(dateFirstOP).format("DD/MM/YYYY");
    },
    computedMachineCenter() {
      const {schedulingMachineCenters, currentSector} = this;
      const computedSectorId = this.computeFieldValue(
        "new_secteur_id",
        currentSector.id,
      );

      return schedulingMachineCenters.find(
        (s: any) => s.secteur_id === computedSectorId,
      );
    },
    currentSector(): Sector {
      const {computedSelectedOps, stationsAndMachinesTags} = this;
      const currentSectorId = computedSelectedOps.reduce(
        (acc: any, current: any): string => {
          const secteur_id = current.new_secteur_id || current.secteur_id;
          if (!acc) return secteur_id;
          else if (acc !== secteur_id)
            return this.$t("scheduling.different_by_of");
          else return acc;
        },
        "",
      );

      const sector = stationsAndMachinesTags.find(
        (x: any) => x.id === currentSectorId,
      );
      return sector || {};
    },
    shouldDisableBtn() {
      return !Object.keys(this.modifications).length;
    },
  },
  methods: {
    computeFieldValue(field: string, defaut: any) {
      const {modifications} = this;
      return modifications[field] || defaut;
    },
    setMachineCenter(value) {
      if (!value?.secteur_id) return;
      this.setFieldValue(value?.secteur_id, "new_secteur_id");
    },
    setFieldValue(value: any, field: string) {
      this.modifications[field] = value;
    },
    computeDate(date: string) {
      return moment(date).format("DD/MM/YYYY");
    },
    async onEditOperationConfirm() {
      const {
        modifications,
        computedSelectedOps,
        clientParameters,
        getCtxSectorOrderedCalendars,
        selectedSimulation,
        shouldHandleOPDuration,
      } = this;
      const {has_gantt_load_adjustment} = clientParameters;
      this.loading = true;

      const data: Record<string, any>[] = [];
      let globalCanAdjustLoadToCapa = false;
      if (modifications.new_date) {
        let ofIds = [...new Set(computedSelectedOps.map(({of_id}) => of_id))];
        for (const selectedOp of computedSelectedOps) {
          const {secteur_id, day_date, op_duration} = selectedOp;
          const isMovingRight = moment(day_date).isBefore(
            modifications.new_date,
          );
          let canAdjustLoadToCapa = has_gantt_load_adjustment && isMovingRight;

          const opSector = this.stationsAndMachinesTags.find(
            ({id}) => id === (modifications.new_secteur_id || secteur_id),
          );
          const nextAvailableDate = this.getCtxNextAvailableDate(
            modifications.new_date,
            false,
            opSector,
          );

          let update: {new_date: string; first_day_duration?: number} = {
            ...modifications,
            new_date: includeOriginalUpdateShiftPlanningInSiblingDate(
              modifications.new_date,
              nextAvailableDate,
            ),
          };

          if (canAdjustLoadToCapa) {
            //OPL-6341 : un edge case assumé est que lorsque plusieurs OPs sont déplacées dans un même batch, en dehors de la fenêtre visible, la capa occupée par une OP ne sera pas prise en compte par l'OP suivante. Par souci de performance, on admet ce cas là
            const [, result] = await this.apiClient.getNextAvailableCapaDay({
              simulation_id: selectedSimulation.id,
              of_ids: ofIds,
              sector_id: opSector.id,
              new_date: nextAvailableDate,
              calendars: getCtxSectorOrderedCalendars(opSector),
              op_duration,
            });
            const {new_date, first_day_duration} = result;
            if (first_day_duration !== 0) {
              update = {
                ...update,
                new_date: includeOriginalUpdateShiftPlanningInSiblingDate(
                  modifications.new_date,
                  new_date,
                ),
                first_day_duration,
              };
            }
            canAdjustLoadToCapa = first_day_duration !== 0;
            if (canAdjustLoadToCapa) globalCanAdjustLoadToCapa = true;
          }

          data.push({initial: selectedOp, update});
          ofIds = ofIds.filter((ofId) => ofId !== selectedOp.of_id);
        }
        const {updated_values} = await this.sendSchedulingLoadEventToBackend({
          data,
          should_return_updated_values: true,
          should_return_insertion_results: true,
          should_handle_op_duration: shouldHandleOPDuration,
          ...(globalCanAdjustLoadToCapa && {has_gantt_load_adjustment}),
        });
        const mappedValues = pgOpsMapFn(updated_values, {
          keepOpsDone: true,
        });
        this.setPgOpsModificationsFromUpdate(computedSelectedOps, mappedValues);
      } else {
        //OPL-4456 : nouveau format des évènements d'ordo
        const {updated_values} = await this.sendSchedulingLoadEventToBackend({
          data: computedSelectedOps.map((op: Record<string, any>) => {
            return {initial: op, update: modifications};
          }),
          should_return_updated_values: true,
          should_handle_op_duration: shouldHandleOPDuration,
        });
        const mappedValues = pgOpsMapFn(updated_values, {
          keepOpsDone: true,
        });
        this.setPgOpsModificationsFromUpdate(computedSelectedOps, mappedValues);
      }

      const sectorIdsToReload = this.computedSelectedOps.map(
        ({secteur_id}) => secteur_id,
      );
      if (modifications.new_secteur_id)
        sectorIdsToReload.push(modifications.new_secteur_id);

      this.ganttEntitiesToReload = {
        ofIds: this.computedSelectedOps.map(({of_id}) => of_id),
        sectorIds: sectorIdsToReload,
      };
      this.loading = false;

      const origin = "dialog";
      if (modifications.new_secteur_id) {
        this.$segment.value.track("[Ordo] Operation Sector Updated", {
          origin,
        });
      }
      if (modifications.new_date) {
        this.$segment.value.track("[Ordo] Operation Planned Date Updated", {
          origin,
        });
      }

      this.operationsEditDialog = false;
    },
  },
});
</script>

<style scoped lang="scss">
.operation-card-edit-dialog {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--v-theme-newLayerBackground));
  padding: 24px;
  font-size: 16px;
}
</style>
