<script setup lang="ts">
import {computed, inject, ref, onMounted, onUnmounted, watch} from "vue";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import slugify from "slugify";
import _ from "lodash";
import FTextField from "@/components/Global/Homemade/Inputs/FTextField.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import FAutoComplete from "@/components/Global/Homemade/Inputs/FAutoComplete.vue";
import {useValidateForm} from "@/composables/useValidateForm";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useSelectedOperations} from "@/stores/selectedOperationsStore";
import {useMainStore} from "@/stores/mainStore";
import {getOperationDuration} from "@/tscript/utils/schedulingUtils";
import {
  getReadableImportParsingRuleValue,
  SchedulingOperation,
  getOPUnit,
} from "@oplit/shared-module";
import {
  OpenDialogFunction,
  OpenSnackbarFunction,
  Segment,
  SendSchedulingLoadEventToBackendParams,
} from "@/interfaces";

const MAX_DISPLAYED_OPERATIONS = 5;

const openDialog = inject<OpenDialogFunction>("openDialog");
const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
const segment = inject<Segment>("segment");

const {clientParameters, variables} = storeToRefs(useMainStore());
const {sendSchedulingLoadEventToBackend, setPgOpsModificationsFromUpdate} =
  useSchedulingStore();
const {
  selectableOperations,
  selectedOperations,
  hasOverlayOpened,
  selectedOperationsWithBatchID,
} = storeToRefs(useSelectedOperations());

const {t} = useI18n();

const showBatchDialog = ref(false);
const displayAllOFs = ref(false);
const isSaving = ref(false);
const batchName = ref<string>(null);
const localSelectedOperations = ref<SchedulingOperation[]>();

const {pass, errors, validateForm} = useValidateForm(
  {batchName, localSelectedOperations},
  {
    batchName: {
      type: "string",
      required: true,
    },
    localSelectedOperations: {
      type: "array",
      minLength: 1,
    },
  },
);

const isUpdate = computed(() => selectedOperationsWithBatchID.value.length > 0);
const buttonText = computed(() => {
  if (!isUpdate.value) return t("OperationsBatchDialog.button_create");

  return selectedOperations.value.some(({batch_id}) => !batch_id)
    ? t("global.add")
    : t("OperationsBatchDialog.button_update");
});
const batchTotalLoad = computed(() => {
  if (localSelectedOperations.value.length === 0) return null;

  const maxLoad = Math.max(
    ...localSelectedOperations.value.map(getOperationDuration),
  );

  return isFinite(maxLoad) ? maxLoad : 0;
});
const displayedOperationsChips = computed(() => {
  const sortedLocalSelectedOperations = localSelectedOperations.value.toSorted(
    (a, b) => a.of_id.localeCompare(b.of_id),
  );

  if (
    localSelectedOperations.value.length < MAX_DISPLAYED_OPERATIONS ||
    displayAllOFs.value
  )
    return sortedLocalSelectedOperations;
  return sortedLocalSelectedOperations.slice(0, MAX_DISPLAYED_OPERATIONS);
});
const localSelectableOperations = computed(() =>
  selectableOperations.value.filter(
    (op) =>
      !localSelectedOperations.value.find(({op_id}) => op.op_id === op_id),
  ),
);
const removedOperations = computed(() =>
  _.differenceBy(
    selectedOperationsWithBatchID.value,
    localSelectedOperations.value,
    "op_id",
  ),
);
function removeOperation(operation: SchedulingOperation) {
  localSelectedOperations.value = localSelectedOperations.value.filter(
    ({op_id}) => op_id !== operation.op_id,
  );
  selectableOperations.value.push(operation);
}
function addOperation(operation: SchedulingOperation) {
  localSelectedOperations.value.push(operation);

  selectableOperations.value = selectableOperations.value.filter(
    ({op_id}) => operation.op_id !== op_id,
  );
}
async function confirmDialog() {
  await validateForm();

  if (!pass.value) return;

  isSaving.value = true;

  try {
    const batchId = slugify(batchName.value, {lower: true});

    const data = [removedOperations.value, localSelectedOperations.value]
      .map((operations, index) => {
        if (operations.length === 0) return [];

        const batch_name = index ? batchName.value : null;
        const batch_id = index ? batchId : null;

        return operations.map((initial) => ({
          update: {
            internal_extra_infos: {
              batch_name,
              batch_id,
            },
          },
          initial,
        }));
      })
      .flat() as SendSchedulingLoadEventToBackendParams["data"];

    await sendSchedulingLoadEventToBackend({
      data,
    });

    // only used to call data refresh
    setPgOpsModificationsFromUpdate([], []);

    selectedOperations.value = [];

    openSnackbar({
      message: t(
        isUpdate.value
          ? "OperationsBatchDialog.confirm_dialog_snackbar_update"
          : "OperationsBatchDialog.confirm_dialog_snackbar_create",
        {batch: batchName.value},
      ),
    });

    segment.value.track(
      isUpdate.value ? "[Ordo] Batch Updated" : "[Ordo] Batch Created",
      {
        name: batchName.value,
        batch_operations_count: localSelectedOperations.value.length,
        removed_operations_count: removedOperations.value.length,
      },
    );
  } catch (error) {
    openSnackbar(null, null, error);
  } finally {
    isSaving.value = false;
    showBatchDialog.value = false;
  }
}
function askDeleteBatch() {
  openDialog({
    header: t("OperationsBatchDialog.delete_dialog_header"),
    message: t("OperationsBatchDialog.delete_dialog_message"),
    validateText: t("OperationsBatchDialog.delete_dialog_confirm"),
    confirmBtnProps: {
      filled: "newPinkRegular",
    },
    type: "warning",
    hidePrefix: true,
    action: deleteBatch,
  });
}
async function deleteBatch() {
  isSaving.value = true;

  try {
    const data = selectedOperations.value.map((initial) => ({
      update: {
        internal_extra_infos: {
          batch_name: null,
          batch_id: null,
        },
      },
      initial,
    }));

    await sendSchedulingLoadEventToBackend({data});

    // only used to call data refresh
    setPgOpsModificationsFromUpdate([], []);

    segment.value.track("[Ordo] Batch Deleted", {
      name: batchName.value,
    });
  } catch (error) {
    openSnackbar(null, null, error);
  } finally {
    isSaving.value = false;
    showBatchDialog.value = false;
  }
}

watch(showBatchDialog, (isShown) => {
  hasOverlayOpened.value = isShown;

  if (isShown) localSelectedOperations.value = [...selectedOperations.value];
});

onMounted(() => {
  if (selectedOperationsWithBatchID.value.length === 0) return;

  const [{batch_name}] = selectedOperationsWithBatchID.value;

  batchName.value = batch_name;
});

onUnmounted(() => (hasOverlayOpened.value = false));
</script>

<template>
  <v-dialog
    v-if="clientParameters.can_batch_operations"
    v-model="showBatchDialog"
    width="664"
    persistent
  >
    <template v-slot:activator="{props}">
      <FButton v-bind="props" width="100%" outlined>
        <span class="fbody-2">
          {{ buttonText }}
        </span>
      </FButton>
    </template>

    <div class="operations-batch-dialog">
      <div class="d-flex flex-column gap-4">
        <h4 class="fd-flex-center gap-4 text-newMainText bold">
          <OplitIcon name="squares" size="24px" />

          {{
            $t(
              isUpdate
                ? "OperationsBatchDialog.title_update"
                : "OperationsBatchDialog.title_create",
              {
                sector: selectedOperations[0].op_name,
              },
            )
          }}
        </h4>

        <div>
          {{ $t("OperationsBatchDialog.subtitle") }}
        </div>
      </div>

      <div class="d-flex flex-column gap-4">
        <strong class="fbody-1 mandatory">
          {{ $t("OperationsBatchDialog.batch_name_input_label") }}
        </strong>

        <FTextField v-model="batchName" :pink-border="errors.batchName" />
      </div>

      <div class="d-flex flex-column gap-4">
        <strong
          :class="{
            'text-newPinkRegular': errors.localSelectedOperations,
          }"
          class="fbody-1"
        >
          {{
            $t(
              "OperationsBatchDialog.operations_label",
              {
                count: localSelectedOperations.length,
              },
              localSelectedOperations.length,
            )
          }}
        </strong>

        <div
          class="operations-batch-dialog__selected-operations"
          :style="`--max-displayed-operations: ${MAX_DISPLAYED_OPERATIONS}`"
        >
          <FChip
            v-for="operation in displayedOperationsChips"
            v-tooltip="getReadableImportParsingRuleValue(operation.of_id)"
            :key="operation.op_id"
            :color="variables.newHover"
            close
            @close="() => removeOperation(operation)"
          >
            {{ getReadableImportParsingRuleValue(operation.of_id) }}
          </FChip>
        </div>

        <span
          v-if="localSelectedOperations.length > MAX_DISPLAYED_OPERATIONS"
          class="align-self-start cursor-pointer"
          @click="() => (displayAllOFs = !displayAllOFs)"
        >
          {{
            displayAllOFs
              ? $t("Simulation.see_less")
              : $t("Simulation.see_more")
          }}
        </span>

        <div class="mt-4">
          <strong class="fbody-2">
            {{ $t("OperationsBatchDialog.search_input_label") }}
          </strong>

          <FAutoComplete
            :value="[]"
            :items="localSelectableOperations"
            :placeholder="$t('global.Search')"
            item-text="of_id"
            item-value="of_id"
            icon="search"
            return-object
            multiple
            @change="(operation) => addOperation(operation)"
          >
            <template #item="{item}">
              {{ getReadableImportParsingRuleValue(item.of_id) }}
            </template>
          </FAutoComplete>
        </div>
      </div>

      <div class="d-flex flex-column gap-2">
        <strong class="fbody-1 flex-1">
          {{ $t("OperationsBatchDialog.group_load_title") }}
        </strong>

        <div class="fbody-2">
          <span v-if="batchTotalLoad">
            {{ batchTotalLoad }} {{ getOPUnit(localSelectedOperations[0]) }}
          </span>

          <span v-else> - </span>
        </div>

        <span class="text-newDisableText">
          {{ $t("OperationsBatchDialog.group_load_subtitle") }}
        </span>
      </div>

      <div class="fd-flex-center justify-end gap-2">
        <FButton
          v-if="isUpdate"
          :disabled="isSaving"
          pink-button
          @click="askDeleteBatch"
        >
          {{ $t("global.delete") }}
        </FButton>

        <FButton
          :disabled="isSaving"
          outlined
          @click="() => (showBatchDialog = false)"
        >
          {{ $t("global.cancel") }}
        </FButton>

        <FButton :loading="isSaving" filled @click="confirmDialog">
          {{ $t("global.validate") }}
        </FButton>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
.operations-batch-dialog {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: rgb(var(--v-theme-newLayerBackground));

  & h4 {
    font-size: 18px;
  }

  & .operations-batch-dialog__selected-operations {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(
      var(--max-displayed-operations),
      minmax(0, 1fr)
    );
  }

  &:deep(.v-chip__content) {
    flex: 1;
  }
}
</style>
