<script setup lang="ts">
import {computed, inject, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import SchedulingOperationWrapper from "@/components/Scheduling/Operations/SchedulingOperationWrapper.vue";
import OperationTags from "@/components/Scheduling/Operations/OperationTags.vue";
import OperationPriorityChip from "@/components/Scheduling/Operations/OperationPriorityChip.vue";
import SchedulingOperationQuantityProgress from "@/components/Scheduling/Operations/SchedulingOperationQuantityProgress.vue";
import SchedulingOperationIsNewTag from "@/components/Scheduling/Operations/SchedulingOperationIsNewTag.vue";
import DelayChip from "@/components/Commons/DelayChip.vue";
import IsSavingSpinner from "@/components/Scheduling/Operations/IsSavingSpinner.vue";
import {useMainStore} from "@/stores/mainStore";
import {
  CSS_OPERATION_CARD_SELECTED_CLASS,
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
} from "@/config/constants";
import {OpenOFSidebarFunction, SchedulingOperation} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {storeToRefs} from "pinia";
import {OF_STATUS} from "@oplit/shared-module";
import {TEST_IDS} from "@/config/constants";
import {useStorage} from "@vueuse/core";

interface SchedulingOperationCardProps {
  operation: SchedulingOperation;
  isOpFromGroup?: boolean;
  isLastOp?: boolean;
  doNotRenderLazy?: boolean;
  showStagnation?: boolean;
  hasDoneOps?: boolean;
}

const props = defineProps<SchedulingOperationCardProps>();
const {operation} = toRefs(props);

const emit = defineEmits<{
  (
    e: "change-status",
    payload: {
      update: Partial<SchedulingOperation>;
      operation: SchedulingOperation;
    },
  );
  (
    e: "change-priority",
    payload: {
      update: Partial<SchedulingOperation>;
      operation: SchedulingOperation;
    },
  ): void;
}>();

const openOFSidebar = inject<OpenOFSidebarFunction>("openOFSidebar");

const {variables} = storeToRefs(useMainStore());
const {t} = useI18n();
const schedulingStore = useSchedulingStore();
const {currentlySavingOfIds, currentlyChangingOfIds} =
  storeToRefs(schedulingStore);

const isManualSortingEnabled = useStorage(
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
  false,
);

const isOPDone = computed(() => props.operation.op_status === OF_STATUS.DONE);
const hasPriority = computed<boolean>(() => !!props.operation.fast_track);
const hasSubheader = (subheader: string): boolean =>
  !!(subheader || props.operation.link_url);

const changePriority = async (priority: string) => {
  const update = {fast_track: priority};
  emit("change-priority", {update, operation: props.operation});
};
</script>

<template>
  <SchedulingOperationWrapper
    :operation="operation"
    :is-op-from-group="isOpFromGroup"
    :do-not-render-lazy="doNotRenderLazy"
    :show-stagnation="showStagnation"
    :has-done-ops="hasDoneOps"
  >
    <template
      v-slot:default="{
        colorCategoryClass,
        compact,
        header,
        isSyntheticView,
        isOngoingLimit,
        isSelected,
        messages,
        operationDelay,
        operationQuantity,
        operationStagnation,
        selectCard,
        showOperation,
        subheader,
      }"
    >
      <div
        :class="[
          {
            [CSS_OPERATION_CARD_SELECTED_CLASS]: isSelected,
            'scheduling-operation-card__list-item': isOpFromGroup,
            'scheduling-operation-card__last-item': isLastOp,
            'scheduling-operation-card__op-status-done': isOPDone,
          },
          colorCategoryClass,
        ]"
        class="scheduling-operation-card__content"
        data-testid="scheduling-operation-card-content"
        @click="selectCard"
      >
        <div class="scheduling-operation-card__header__container">
          <SchedulingOperationIsNewTag :operation="operation" />

          <OperationPriorityChip
            v-if="hasPriority"
            :op="operation"
            @change-priority="changePriority"
            edit-only
          />

          <strong
            v-tooltip="header"
            class="scheduling-operation-card__header text-ellipsis text-14 semi-bold"
            data-testid="scheduling-operation-card-header"
          >
            {{ header }}
          </strong>

          <span v-if="operation.is_smoothed && operation.smoothing_position">
            {{ `(${operation.smoothing_position})` }}
          </span>

          <IsSavingSpinner
            :is-changing="currentlyChangingOfIds[operation.of_id]"
            :is-saving="currentlySavingOfIds[operation.of_id]"
            :is-selected="isSelected"
            :has-error="currentlySavingOfIds[operation.of_id] === false"
          />

          <div
            v-if="isManualSortingEnabled && operation.wip_order != null"
            class="fd-flex-center"
          >
            <DevHelper>{{ `(${operation.wip_order})` }}</DevHelper>

            <OplitIcon
              :fill="variables.newPrimaryRegular"
              name="hand-swipe-left"
              size="20px"
            />
          </div>

          <DelayChip :delay="operationDelay" />
        </div>

        <div
          v-if="hasSubheader(subheader)"
          class="scheduling-operation-card__subheader text-14"
        >
          <span
            v-if="subheader"
            data-testid="scheduling-operation-card-subheader"
          >
            {{ subheader }}
          </span>

          <a
            v-if="operation.link_url"
            :href="operation.link_url"
            target="_blank"
          >
            <vue-feather tag="div" type="external-link" size="12" />
          </a>
        </div>

        <div class="scheduling-operation-card__info">
          <OplitIcon
            v-if="operation.batch_id"
            v-tooltip="operation.batch_name"
            name="squares"
            size="20px"
            :stroke="variables.newPrimaryDark2"
            class="mr-1"
          />

          <span
            class="scheduling-operation-card__info__quantity"
            data-testid="scheduling-operation-card-quantity"
          >
            {{ operationQuantity.txt }}
          </span>

          <span
            v-if="operation.duration"
            data-testid="scheduling-operation-card-duration-unit"
            >{{ `(h)` }}</span
          >
        </div>

        <div
          v-if="isOngoingLimit"
          v-tooltip.start="t('operation.max_wip')"
          class="scheduling-operation-card__is-limit"
        />

        <div
          v-if="showStagnation"
          v-tooltip="t('scheduling.stagnation')"
          class="scheduling-operation-card__stagnation"
          data-testid="scheduling-operation-card-stagnation"
        >
          <vue-feather type="watch" size="14" />

          {{ operationStagnation }}
        </div>

        <div
          class="scheduling-operation-card__chips"
          data-testid="scheduling-operation-card-chips"
        >
          <OperationTags
            :operation="operation"
            :is-synthetic-view="isSyntheticView"
            @change-status="
              ({update}) => emit('change-status', {update, operation})
            "
          />

          <vue-feather
            v-if="messages.length && !compact"
            :stroke="variables.newDisableText"
            type="message-circle"
            size="16"
            class="has-messages position-relative cursor-pointer"
            @click.stop="openOFSidebar(operation, {defaultSelectedTab: 1})"
          />

          <vue-feather
            v-if="!compact"
            class="scheduling-operation-card__maximize"
            type="maximize-2"
            size="16px"
            :data-testid="TEST_IDS.SCHEDULING_OPERATION_CARD__MAXIMIZE_ICON"
            @click.stop="showOperation"
          />
        </div>

        <SchedulingOperationQuantityProgress :operation="operation" />
      </div>
    </template>
  </SchedulingOperationWrapper>
</template>

<style scoped lang="scss">
.scheduling-operation-card__header__container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.scheduling-operation-card__header {
  flex: 1;
  line-height: 1;
}

.scheduling-operation-card__subheader {
  color: rgb(var(--v-theme-newDisableText));
  line-height: 1;
}

.scheduling-operation-card__stagnation {
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgb(var(--v-theme-newMainText));
  font-size: 12px;
}

.scheduling-operation-card__is-limit {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 2px;
    background: rgb(var(--v-theme-newPinkRegular));
    z-index: 1;
  }
}

.scheduling-operation-card__content {
  min-height: inherit;

  &.scheduling-operation-card__op-status-done {
    border-color: rgb(var(--v-theme-newSelected));
    background-color: rgb(var(--v-theme-newLightGrey));

    .scheduling-operation-card__subheader {
      color: rgb(var(--v-theme-newSubText));
    }

    .scheduling-operation-card__info {
      & .scheduling-operation-card__info__quantity {
        color: rgb(var(--v-theme-newSubText));
      }
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgb(var(--v-theme-newLayerBackground));
  padding: 8px;
  border: 1px solid rgb(var(--v-theme-newSelected));
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden; // solely added for colors categories

  &[class*="--colors-categories"] {
    padding-left: 16px;
  }

  &.selected-card {
    border-color: rgb(var(--v-theme-newPrimaryRegular));
    background-color: rgb(var(--v-theme-newHover));
  }
}

.scheduling-operation-card__info {
  display: flex;
  align-items: center;

  & .scheduling-operation-card__info__quantity {
    color: rgb(var(--v-theme-newMainText));
  }
}

.scheduling-operation-card__chips {
  display: flex;
  align-items: center;

  & .scheduling-operation-card__maximize {
    margin-left: auto;
    cursor: pointer;
    flex: 0 0 16px;
  }
}
</style>
