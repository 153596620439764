import {Ref, computed, ref} from "vue";

type RuleType = "string" | "array";

type ValidatorFn<T = any> = (value: T) => boolean;

type Rules = {
  [fieldName: string]: {
    type: RuleType;
    required?: boolean;
    minLength?: number;
    validator?: ValidatorFn;
  };
};

type CheckBaseFnArgs = {value: any; type: RuleType};
type CheckMinLengthFnArgs = CheckBaseFnArgs & {minLength: number};
const check = {
  type: ({value, type}: CheckBaseFnArgs) => {
    if (type === "array") return Array.isArray(value);

    return typeof value === type;
  },
  required: ({value}: Pick<CheckBaseFnArgs, "value">) => {
    return !["", null, undefined].includes(value);
  },
  minLength: ({value, type, minLength}: CheckMinLengthFnArgs) => {
    if (!["array", "string"].includes(type)) return false;

    if (!value) return false;
    if (minLength == null) return false;

    return value.length >= minLength;
  },
};

export function useValidateForm(form: Record<string, Ref<any>>, rules: Rules) {
  const hasExecuted = ref(false);
  const errors = ref<Record<string, boolean>>({});

  const pass = computed(() => {
    if (!hasExecuted.value) return false;

    return Object.keys(errors.value).length === 0;
  });

  function isValidField(field: keyof typeof form) {
    if (!rules[field]) return true;

    const {type, required, minLength, validator} = rules[field];
    const value = form[field].value;

    if (!check.type({value, type})) return false;

    if (required && !check.required({value})) return false;

    if (minLength && !check.minLength({value, type, minLength})) return false;

    if (validator && !validator(value)) return false;

    return true;
  }
  async function validateForm() {
    hasExecuted.value = false;

    for (const fieldName of Object.keys(form)) {
      if (!isValidField(fieldName)) errors.value[fieldName] = true;
      else delete errors.value[fieldName];
    }

    hasExecuted.value = true;
  }

  return {errors, pass, validateForm};
}
