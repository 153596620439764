<template>
  <div class="dropdown-list d-flex flex-column" :style="{maxHeight}">
    <div class="dropdown-header" v-if="title || !hideSearch">
      <h4 v-if="title">{{ title }}</h4>

      <FTextField
        v-if="!hideSearch"
        v-model="search"
        clearable
        flush-label
        class="dropdown-search"
        icon="search"
        :label="$t('global.search')"
        @update:model-value="onSearchInputChange"
        @click:clear="() => (search = '')"
        @click.stop
      />
    </div>
    <v-list
      :class="['dropdown-list', listClass, {'keep-scrollbar': keepScrollbar}]"
      data-cy="dropdown-list"
    >
      <slot name="prepend-item" />

      <v-list-item v-if="!items.length && !hideSearch" class="font-italic">
        {{ $t("Commons.dropdown_no_item_placeholder") }}
      </v-list-item>

      <v-lazy
        v-for="item in items"
        :key="item[itemValue]"
        :min-height="16"
        class="full-width"
      >
        <v-list-item
          :class="{
            'v-list-item--active': isSelectedItem(item),
          }"
          :disabled="item.disabled || false"
          class="dropdown-item"
          @click="(e) => onItemClick(e, item)"
        >
          <div class="item-content flex-1">
            <div
              class="d-flex justify-space-between items-center"
              :data-cy="`filter-list-${item[itemText]}`"
            >
              <slot name="item" v-bind="{item}">
                <div v-if="tagIncluded && isTagged(item[itemText])">
                  <span>{{ taggedItem(item[itemText]).txt }}</span>

                  <span class="fbody-2 text-newSubText">
                    - {{ taggedItem(item[itemText]).tag }}
                  </span>
                </div>

                <div class="f-dropdown-list-item-content" v-else>
                  <slot
                    v-if="typeof item === 'object'"
                    v-bind="item"
                    name="item-prepend"
                  />
                  <div class="fd-flex-center full-width">
                    <vue-feather
                      v-if="multiSelect"
                      class="mr-3 plus-btn"
                      tag="div"
                      :type="isSelectedItem(item) ? 'check-square' : 'square'"
                    />

                    <span class="flex-1">
                      {{ typeof item === "object" ? item[itemText] : item }}
                    </span>
                  </div>
                </div>
              </slot>

              <slot
                v-if="typeof item === 'object'"
                name="item-append"
                v-bind="item"
              />
            </div>
          </div>
        </v-list-item>
      </v-lazy>

      <slot name="append-item" />
    </v-list>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import {watch, defineComponent, ref} from "vue";
import FTextField from "@/components/Global/Homemade/Inputs/FTextField.vue";
import {
  PROPS_FDROPDOWN_ITEM_VALUE_DEFAULT,
  TAG_SEPARATOR,
} from "@/config/constants";

export default defineComponent({
  name: "figma-dropdown-list",
  components: {FTextField},
  props: {
    value: {type: [String, Object, Number], default: () => {}},
    itemText: {type: String, default: "name"},
    itemValue: {type: String, default: PROPS_FDROPDOWN_ITEM_VALUE_DEFAULT},
    items: Array,
    returnObject: Boolean,
    title: String,
    tagIncluded: Boolean,
    maxHeight: {type: String, default: ""},
    multiSelect: {type: Boolean, default: false},
    defaultSelected: {type: Array, default: () => []},
    listClass: {type: String, default: ""},
    hideSearch: {type: Boolean, default: true},
    noItemsText: {type: String, default: null},
    keepScrollbar: Boolean,
  },
  emits: ["update-multi-select", "click", "filter-list"],
  setup(props) {
    const menu = ref(false);
    const selected = ref([...(props.defaultSelected || [])]);
    const search = ref("");

    function getItemKey(item: unknown): Record<string, unknown> | unknown {
      return props.returnObject ? item : item[props.itemValue];
    }

    watch(
      () => props.defaultSelected,
      (newVal) => {
        selected.value = [...newVal];
      },
    );

    return {
      menu,
      selected,
      search,
      getItemKey,
    };
  },

  computed: {
    // string displayed when the list (filtered) is empty
    placeholderNoItems(): string {
      return this.noItemsText || this.$t("");
    },
  },

  methods: {
    // returns whether or not an item is selected
    isSelectedItem(item: any): boolean {
      if (this.multiSelect) {
        return this.defaultSelected.some((selectedItem) =>
          _.isEqual(selectedItem, this.getItemKey(item)),
        );
      } else {
        const {returnObject, itemValue, value} = this;
        // required for specific cases / seen for SimulationDropdown where the store current simulation is null
        if (!value) return false;
        if (returnObject && typeof value === "object")
          return value[itemValue] === item[itemValue];
        else if (typeof item === "object") return value === item[itemValue];
        else return value === item;
      }
    },
    toggleItemInSelected(item: any) {
      const itemIndex = this.selected.findIndex((selectedItem) =>
        _.isEqual(selectedItem, this.getItemKey(item)),
      );
      if (itemIndex === -1) this.selected.push(this.getItemKey(item));
      else this.selected = this.selected.filter((_, i) => i !== itemIndex);
    },
    onItemClick(e: any, item: any) {
      if (this.multiSelect) {
        e.stopPropagation();
        this.toggleItemInSelected(item);
        this.$emit("update-multi-select", this.selected);
      } else this.$emit("click", item);
    },
    isTagged(value: any) {
      return (value || "").includes(TAG_SEPARATOR);
    },

    taggedItem(value: any) {
      const [txt, tag] = (value || "").split(TAG_SEPARATOR);
      // i18n fallbacks (as in $t("key", "fallbackKey")) don't seem to work
      // reliably, so I resorted to checking manually if the translation was done
      // properly or if the tag is not referenced in fr/en.json
      const translatedTag = this.$t("Tags." + tag);
      return {txt, tag: translatedTag.includes("Tags.") ? tag : translatedTag};
    },

    onSearchInputChange(value: string): void {
      this.search = value;
      this.$emit("filter-list", value);
    },
  },
});
</script>

<style scoped lang="scss">
/* this class is used as selector in other components */
.f-dropdown-list-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-header {
  position: sticky;
  top: 0;
  background: rgb(var(--v-theme-newLayerBackground));
  z-index: 1;
}

.dropdown-list.hide-selected {
  & .v-list-item--active {
    display: none;
  }
}
</style>
