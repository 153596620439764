import {defineStore, storeToRefs} from "pinia";
import {ref, computed} from "vue";
import _ from "lodash";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {SchedulingOperation} from "@/interfaces";

export const useSelectedOperations = defineStore("selectedOperations", () => {
  const {currentlyChangingOfIds, currentlySavingOfIds} = storeToRefs(
    useSchedulingStore(),
  );

  const selectedOperations = ref<SchedulingOperation[]>([]);
  const selectableOperations = ref<SchedulingOperation[]>([]);
  const hasOverlayOpened = ref(false);

  const selectedOperationsIDs = computed(() =>
    selectedOperations.value.map(({op_id}) => op_id),
  );
  const formattedSelectedOperationsForRetrocompatibility = computed<{
    [op_id: string]: SchedulingOperation;
  }>(() =>
    selectedOperations.value.reduce(
      (acc, operation) => ({
        ...acc,
        [operation.op_id]: operation,
      }),
      {},
    ),
  );
  const selectedOperationsWithBatchID = computed(() =>
    selectedOperations.value.filter(({batch_id}) => batch_id),
  );

  function toggleSelectedOperations(operations: SchedulingOperation[]) {
    if (!operations?.[0]) {
      selectedOperations.value = [];
      return;
    }

    const [{secteur_id}] = operations;

    if (selectedOperations.value[0]?.secteur_id !== secteur_id)
      selectedOperations.value = [];

    if (
      operations.every(({op_id}) => selectedOperationsIDs.value.includes(op_id))
    ) {
      selectedOperations.value = selectedOperations.value.filter(
        ({op_id}) => !operations.find((operation) => operation.op_id === op_id),
      );
    } else selectedOperations.value.push(...operations);
  }
  function setSelectableOperations(operations: SchedulingOperation[]) {
    if (!operations?.length) selectableOperations.value = [];
    else {
      selectableOperations.value = operations.filter(
        ({op_id, batch_id}) =>
          !batch_id && !selectedOperationsIDs.value.includes(op_id),
      );
    }
  }
  function havePendingChangesOperations(operations: SchedulingOperation[]) {
    return operations.some(({of_id}) => currentlyChangingOfIds.value[of_id]);
  }
  function areSavingOperations(operations: SchedulingOperation[]) {
    return operations.some(({of_id}) => currentlySavingOfIds.value[of_id]);
  }
  function haveErrorsOnSaveOperations(operations: SchedulingOperation[]) {
    return operations.some(
      ({of_id}) => currentlySavingOfIds.value[of_id] === false,
    );
  }
  function isSelectedOperationsGroup(operations: SchedulingOperation[]) {
    return operations.every(({op_id}) =>
      selectedOperationsIDs.value.includes(op_id),
    );
  }
  function getOperationsAggregatedQuantities(
    operations: SchedulingOperation[],
  ) {
    const defaultBatchId = "__no_batch__";

    const mappedOperations = operations.map(
      ({batch_id, quantite_of, op_duration, quantite_op, quantite}) => ({
        batch_id,
        quantite_of: +quantite_of || 0,
        quantite_op: +op_duration || +quantite_op || +quantite || 0,
      }),
    );

    const operationsByBatchId = _.groupBy(
      mappedOperations,
      ({batch_id}) => batch_id || defaultBatchId,
    );

    let quantiteOf = 0;
    let quantiteOp = 0;

    for (const [batch_id, ops] of Object.entries(operationsByBatchId)) {
      quantiteOf += _.sumBy(ops, "quantite_of");

      if (batch_id === defaultBatchId)
        quantiteOp += _.sumBy(ops, "quantite_op");
      else {
        const {quantite_op} = _.maxBy(ops, "quantite_op");
        quantiteOp += quantite_op;
      }
    }

    return {
      quantite_of: _.round(quantiteOf, 2),
      quantite_op: _.round(quantiteOp, 2),
    };
  }

  return {
    selectedOperations,
    selectableOperations,
    toggleSelectedOperations,
    setSelectableOperations,
    formattedSelectedOperationsForRetrocompatibility,
    havePendingChangesOperations,
    areSavingOperations,
    haveErrorsOnSaveOperations,
    selectedOperationsIDs,
    hasOverlayOpened,
    isSelectedOperationsGroup,
    getOperationsAggregatedQuantities,
    selectedOperationsWithBatchID,
  };
});
