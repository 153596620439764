import {computed, inject, ref} from "vue";
import _ from "lodash";
import slugify from "slugify";
import {storeToRefs} from "pinia";
import {Segment} from "@/interfaces";
import {dbHelper} from "@/tscript/utils/requirements";
import {DEFAULT_LOAD_UNITS} from "@oplit/shared-module";
import {STOCKS_DEFAULT_LOAD_QUANTITY_FIELD} from "@/config/constants";
import {useMainStore} from "@/stores/mainStore";
import {i18n} from "@/i18n";

const LOAD_QUANTITY_FIELDS_MAPPING = {
  quantite_op: {
    key: "load",
    unitKey: "unite_op",
    unitFallback: DEFAULT_LOAD_UNITS.OP,
  },
  quantite_op_2: {
    key: "quantite_op_2",
    unitKey: "unite_op_2",
    unitFallback: DEFAULT_LOAD_UNITS.OP_2,
  },
  quantite_of: {
    key: "quantite_of",
    unitKey: "unite_of",
    unitFallback: DEFAULT_LOAD_UNITS.OF,
  },
};

const availableLoadQuantityFields = ref<
  {
    key: string;
    label: string;
    unit: string;
  }[]
>([]);

const currentPDPLoadQuantityField = ref("quantite_op");
const currentDemandsLoadQuantityField = ref("quantite_of");
const currentStocksLoadQuantityField = ref(STOCKS_DEFAULT_LOAD_QUANTITY_FIELD);

export const useAvailableLoadQuantityFields = () => {
  const {t, te} = i18n;
  const segment = inject<Segment>("segment");

  const mainStore = useMainStore();

  const hasSecondaryPDPLoadQuantityField = computed(() => {
    const fieldKeys = availableLoadQuantityFields.value.map(
      (field) => field.key,
    );
    return fieldKeys.includes("quantite_op_2");
  });

  const getAvailablePDPLoadQuantityFields = computed(() => {
    if (mainStore.hasStock) return availableLoadQuantityFields.value;

    return availableLoadQuantityFields.value.filter(
      (field) => field.key !== "quantite_of",
    );
  });
  const getAvailableStocksLoadQuantityFields = computed(
    () => availableLoadQuantityFields.value,
  );

  const getCurrentPDPLoadQuantityFieldUnit = computed(() =>
    getLoadQuantityFieldUnit(currentPDPLoadQuantityField.value),
  );
  const getCurrentDemandsLoadQuantityFieldUnit = computed(() =>
    getLoadQuantityFieldUnit(currentDemandsLoadQuantityField.value),
  );
  const getCurrentStocksLoadQuantityFieldUnit = computed(() =>
    getLoadQuantityFieldUnit(currentStocksLoadQuantityField.value),
  );
  const getCurrentStocksLoadQuantityFieldConfig = computed(() =>
    getLoadQuantityFieldConfig(currentStocksLoadQuantityField.value),
  );

  function getDisplayedUnit(unit: string) {
    const slugUnit = slugify(unit);

    return te(`Commons.${slugUnit}`) ? t(`Commons.${slugUnit}`) : unit;
  }

  /**
   * TODO: simplify this function by making a request to pg `daily_load_new` with LIMIT 1
   * if the row has values defined for each field, we should return them all
   */
  async function loadAvailableLoadQuantityFields(is_stocks?: boolean) {
    const {apiClient, clientId, simulation, clientParameters, isDevEnv} =
      storeToRefs(mainStore);

    if (!clientId.value || !simulation.value?.id) return;

    const possibleFieldNames = Object.keys(LOAD_QUANTITY_FIELDS_MAPPING);

    const [parsingRules] =
      (await dbHelper.getAllDataFromCollectionWithAll("import_parsing_rules", {
        where: [{field: "client_id", compare: "==", value: clientId.value}],
      })) || [];

    const [, op] = await apiClient.value.getLoadQuantities({
      simulation_id: simulation.value?.id,
      is_stocks,
      has_stock: clientParameters.value?.has_module_stocks,
    });

    const availableFields = [];
    if (!parsingRules) {
      possibleFieldNames.forEach((fieldName) => {
        const field = LOAD_QUANTITY_FIELDS_MAPPING[fieldName];
        if (op?.[field.key] != null) availableFields.push(fieldName);
      });
    } else {
      const {LOAD = {}} = parsingRules;
      Object.keys(LOAD || {}).forEach((fieldName) => {
        if (possibleFieldNames.includes(fieldName))
          availableFields.push(fieldName);
      });
    }

    const fields = _.uniq(["quantite_of", "quantite_op", ...availableFields]);

    availableLoadQuantityFields.value = fields.map((fieldName) => {
      const unit = getLoadQuantityFieldUnit(fieldName, op);

      return {
        key: fieldName,
        label:
          getDisplayedUnit(unit) + (isDevEnv.value ? ` (${fieldName})` : ""),
        unit,
      };
    });
  }

  function getLoadQuantityFieldConfig(fieldName: string) {
    const field = availableLoadQuantityFields.value.find(
      (field) => field.key === fieldName,
    );
    return field || LOAD_QUANTITY_FIELDS_MAPPING[fieldName];
  }
  function getLoadQuantityFieldUnit(
    fieldName: string,
    op?: {
      load?: number;
      unite_op?: string;
      quantite_op_2?: number;
      unite_op_2?: string;
      quantite_of?: number;
      unite_of?: string;
    },
  ): string {
    if (!op) {
      const field = availableLoadQuantityFields.value.find(
        (field) => field.key === fieldName,
      );
      return getDisplayedUnit(
        field?.unit || LOAD_QUANTITY_FIELDS_MAPPING[fieldName].unitFallback,
      );
    }

    const field = LOAD_QUANTITY_FIELDS_MAPPING[fieldName];

    return getDisplayedUnit(op[field.unitKey] || field.unitFallback);
  }

  function getCurrentStocksDemandsLoadQuantityField(isStocks: boolean) {
    return isStocks
      ? currentStocksLoadQuantityField.value
      : currentDemandsLoadQuantityField.value;
  }
  function setCurrentStocksDemandsLoadQuantityField(
    field: {key: string; label: string},
    isStocks: boolean,
  ) {
    if (isStocks) setCurrentStocksLoadQuantityField(field);
    else setCurrentDemandsLoadQuantityField(field);
  }
  function setCurrentPDPLoadQuantityField(field: {key: string; label: string}) {
    const oldField = currentPDPLoadQuantityField.value;
    currentPDPLoadQuantityField.value = field.key;

    segment.value.track("[PDP] Load table unit changed", {
      new: field.key,
      old: oldField,
    });
  }
  function setCurrentDemandsLoadQuantityField(field: {
    key: string;
    label: string;
  }) {
    const oldField = currentDemandsLoadQuantityField.value;
    currentDemandsLoadQuantityField.value = field.key;

    segment.value.track("[Stocks] Demands table unit changed", {
      new: field.key,
      old: oldField,
    });
  }
  function setCurrentStocksLoadQuantityField(field: {
    key: string;
    label: string;
  }) {
    const oldField = currentStocksLoadQuantityField.value;
    currentStocksLoadQuantityField.value = field.key;

    segment.value.track("[Stocks] Stocks table unit changed", {
      new: field.key,
      old: oldField,
    });
  }

  return {
    availableLoadQuantityFields,
    currentPDPLoadQuantityField,
    currentStocksLoadQuantityField,
    currentDemandsLoadQuantityField,
    getCurrentPDPLoadQuantityFieldUnit,
    getCurrentStocksLoadQuantityFieldUnit,
    getCurrentDemandsLoadQuantityFieldUnit,
    getAvailablePDPLoadQuantityFields,
    getAvailableStocksLoadQuantityFields,
    hasSecondaryPDPLoadQuantityField,
    loadAvailableLoadQuantityFields,
    getLoadQuantityFieldUnit,
    setCurrentPDPLoadQuantityField,
    getCurrentStocksDemandsLoadQuantityField,
    setCurrentStocksDemandsLoadQuantityField,
    setCurrentStocksLoadQuantityField,
    setCurrentDemandsLoadQuantityField,
    getCurrentStocksLoadQuantityFieldConfig,
  };
};
