<script setup lang="ts">
import {computed, ref} from "vue";
import {useFormComponents} from "@/composables/formComponents";
import {VTextField, VTextarea} from "vuetify/lib/components/index.mjs";

interface FTextFieldProps {
  height?: string;
  modelValue: string | number | undefined;
  suffix?: string;
  label?: string;
  icon?: string;
  iconStroke?: string;
  iconFill?: string;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  flushLabel?: boolean;
  clearable?: boolean;
  readonly?: boolean;
  light?: boolean;
  type?: string;
  required?: boolean;
  pattern?: string;
  iconSize?: string;
  autofocus?: boolean;
  pinkBorder?: boolean;
  alertIfNan?: boolean;
  outlined?: boolean | string[];
  // as of yet will not work with the #append-inner slot populated
  copyable?: boolean;
  canSeePassword?: boolean;
  dataCy?: string;
  dataTestid?: string;
  flex?: boolean;
  maxRows?: number;
}

const props = withDefaults(defineProps<FTextFieldProps>(), {
  height: "40px",
  light: false,
  type: "text",
  iconSize: "24",
  autofocus: false,
  pinkBorder: false,
  alertIfNan: false,
  outlined: false,
  copyable: false,
  canSeePassword: false,
  maxRows: 1,
});

const emit = defineEmits<{
  (e: "change", value: string);
  (e: "update:model-value", value: string);
  (e: "blur", event: Event);
  (e: "focus", event: Event);
  (e: "keydown", event: Event);
  (e: "click", event: Event);
}>();

const {formComponentStyle} = useFormComponents(props);

const localType = ref(null);
const copyIcon = ref("copy");

const shouldDisplayPinkBorder = computed(() => {
  const {pinkBorder, alertIfNan, modelValue} = props;
  const typedValue: any = (modelValue + "").replace(",", ".");
  return pinkBorder || (alertIfNan && isNaN(typedValue));
});

const isArea = computed(() => props.maxRows > 1);

const getTextFieldComponent = computed(() =>
  !isArea.value ? VTextField : VTextarea,
);
const inputHeight = computed(() => props.height || "40px");

function copyInputContent(): void {
  navigator.clipboard.writeText(`${props.modelValue}`);

  copyIcon.value = "check";
  setTimeout(() => (copyIcon.value = "copy"), 1000);
}
</script>

<template>
  <div
    :class="{'flex-1': flex}"
    class="f-text-field__wrapper"
    @click="(e) => emit('click', e)"
  >
    <label v-if="label && !flushLabel" class="text-field-label">
      {{ label }}
    </label>
    <Component
      :is="getTextFieldComponent"
      :class="{
        'text-field': true,
        'text-area': isArea,
        'text-field-light': light,
        'text-field--disabled': disabled,
        'mt-2': label && !flushLabel,
        'text-field-pink-border': shouldDisplayPinkBorder,
      }"
      :style="formComponentStyle"
      :model-value="modelValue"
      :suffix="suffix"
      :label="flushLabel ? label : ''"
      :placeholder="placeholder"
      :clearable="clearable"
      :id="id"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
      :pattern="pattern"
      :type="localType || type"
      :rows="maxRows"
      :data-cy="dataCy"
      :data-testid="dataTestid"
      variant="outlined"
      density="compact"
      persistent-hint
      hide-details
      no-resize
      @change="(e) => emit('change', e.target.value)"
      @update:model-value="(v) => emit('update:model-value', v)"
      @click:clear="() => emit('update:model-value', '')"
      @blur="(e) => emit('blur', e)"
      @focus="(e) => emit('focus', e)"
      @keydown="(e) => emit('keydown', e)"
      @keydown.left.stop="(e) => emit('keydown', e)"
      @keydown.right.stop="(e) => emit('keydown', e)"
    >
      <template v-if="!isArea" #prepend-inner>
        <slot name="prepend-inner">
          <vue-feather
            v-if="icon"
            class="text-field-icon mr-2"
            tag="div"
            :size="iconSize"
            :type="icon"
            :stroke="iconStroke"
            :fill="iconFill"
          />
        </slot>
      </template>

      <template v-if="!isArea" #append-inner>
        <slot name="append-inner">
          <vue-feather
            v-if="type === 'password' && canSeePassword"
            :type="localType ? 'eye-off' : 'eye'"
            class="cursor-pointer"
            @click="() => (localType = localType ? null : 'text')"
          />

          <vue-feather
            v-if="copyable"
            v-tooltip="$t('Parametres.copy_field')"
            :type="copyIcon"
            class="cursor-pointer"
            @click="copyInputContent"
          />
        </slot>
      </template>
      <slot name="input-multitag" />
    </Component>
  </div>
</template>

<style scoped lang="scss">
.v-input--density-compact {
  --v-input-control-height: v-bind(inputHeight);
}
</style>

<style lang="scss">
.text-field {
  border-radius: 8px;
  background: rgb(var(--v-theme-newSubBackground));
  outline: none;

  &-light {
    background: rgb(var(--v-theme-newLayerBackground));
  }

  &-icon {
    min-width: 24px;
  }

  &-label {
    font-size: 16px;
    color: rgb(var(--v-theme-newSubText));
  }

  input {
    color: rgb(var(--v-theme-newSubText)) !important;
    margin: 0;

    &::placeholder {
      color: rgb(var(--v-theme-newSelected)) !important;
    }
  }

  label {
    padding: 0;
  }

  .v-field {
    border-radius: 8px;
  }

  .v-field__input {
    // This now designates the wrapping div rather than the input itself, so
    // we need to bypass default vuetify parameters (OPL-4737)
    padding: 0;
    margin: 0;
    min-height: 0;

    & > input {
      min-height: v-bind(inputHeight);
      margin: 0;
    }
  }

  .v-field__outline {
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 8px;
    & [class*="v-field__outline__"] {
      border-width: 0px;
      &::before,
      &::after {
        border-width: 0px;
      }
    }
  }

  .v-text-field__suffix {
    min-height: v-bind(inputHeight);
    padding: 0 4px;
    align-self: center;
    color: rgb(var(--v-theme-newSubText));
  }

  .v-field__prepend-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
    color: rgb(var(--v-theme-newSubText));
  }

  &.text-area {
    height: calc(v-bind(inputHeight) + v-bind(maxRows - 1) * 24px) !important;

    .v-field__input {
      padding: 8px;
      margin: 0;
      min-height: 0;

      & > input {
        min-height: v-bind(inputHeight);
        padding: 8px 0;
        margin: 0;
      }
    }
  }

  // States
  &--disabled {
    background-color: rgb(var(--v-theme-newDisableBG)) !important;
    border-color: rgb(var(--v-theme-pinkRegular));

    input {
      color: rgb(var(--v-theme-newDisableText)) !important;
    }

    .v-field__outline {
      border: none !important;
    }
  }

  &:hover,
  & .v-field--focused {
    .v-field__prepend-inner {
      color: rgb(var(--v-theme-newMainText)) !important;
    }
  }

  &:hover {
    background: rgb(var(--v-theme-newHover));

    .v-field__outline {
      border-color: rgb(var(--v-theme-newMainText));
    }

    & .v-text-field__prefix,
    & .v-text-field__suffix {
      opacity: 1;
    }
  }

  &:active,
  &:focus,
  & .v-field--focused {
    background: rgb(var(--v-theme-newLayerBackground));

    input {
      color: rgb(var(--v-theme-newMainText)) !important;
    }

    .v-text-field__suffix {
      color: rgb(var(--v-theme-newSubText));
    }
  }

  &:focus,
  & .v-field--focused {
    label {
      color: rgb(var(--v-theme-newPrimaryRegular));
      background-color: rgb(var(--v-theme-newLayerBackground));
      padding: 0 4px;
    }

    .v-field__outline {
      border-color: rgb(var(--v-theme-newPrimaryRegular));
    }
  }

  &:active,
  & .v-field--focused {
    .v-field__outline {
      border-color: rgb(var(--v-theme-newMainText));
    }
  }

  &-pink-border {
    &,
    &:hover,
    &:focus,
    &:active {
      .v-field__outline {
        border-color: rgb(var(--v-theme-newPinkRegular));
      }
    }
  }
}
</style>
